<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">网关快速原型开发</h1>
				<p data-caption-delay="100" class="wow fadeInRight">为客户提供快速适配的原型工具，助力整车及控制器开发</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<section class="solution-intros gateway-solution-intro wow fadeInLeft">
			<div class="container">
				<div class="normal-title">解决方案介绍</div>
				<div class="all-intro">
					<div class="gateway-intro">
						<div class="intro-text">
							<p>东信创智的网关快速原型提供完整的软硬件解决方案，可根据客户需求快速部署适配客户的通信矩阵，缩短用户的功能验证和部署及适配的时间。目前东信创智的网关快速原型已经提供给部分OEM使用，助力客户的新车型研发。
							</p>
						</div>
						<div class="intro-text">
							<p>网关快速原型硬件设计采用车规级芯片及器件，微控制采用NXP公司的MPC5748G，特性如下：</p>
							<ul>
								<li>高性能：32位，2颗Power Architecture®e200z4 @ 160 MHz/Power，1 颗 Architecture®e200z2 @ 80 MHz
								</li>
								<li>高稳定性：AEC-Q100，工作温度范围-40 ~ 125℃</li>
								<li>功能丰富：AVB，Ethernet，USB，FlexRay，LIN，CANFD，SAI，DSPI，I2C，SDHC</li>
								<li>安全性：HSM，ISO 26262/IEC 61508</li>
								<li>768KB RAM，6MB Flash</li>
							</ul>
						</div>
						<div class="intro-text">
							<p>硬件资源：</p>
							<ul>
								<li>5路100BASE-T1，1路100BASE-TX</li>
								<li>6路CAN/CANFD</li>
								<li>1路LIN Master/Slave</li>
								<li>3路数字输入/2路数字输出</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="solution-functions solution-intros gateway-solution-intro wow fadeInRight">
			<div class="container">
				<div class="normal-title">功能特点</div>
				<div class="all-intro">
					<div class="gateway-intro product-vtd">
						<!--            <div class="intro-text">-->
						<!--              <p>东信创智的网关快速原型可依据客户需求进行功能定制，可实现功能如下：</p>-->
						<!--              <div class="two-column">-->
						<!--&lt;!&ndash;                <ul class="left-column" ref="leftList"></ul>&ndash;&gt;-->
						<!--&lt;!&ndash;                <ul class="right-column" ref="rightList"></ul>&ndash;&gt;-->
						<!--              </div>-->
						<!--            </div>-->
						<div class="vtd-technical-advantages vtd-system-picture">
							<p>东信创智的网关快速原型可依据客户需求进行功能定制，可实现功能如下：</p>
							<el-row :gutter="10">
								<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
									<div class="technical-advantages">
										<ol ref="leftList">
											<!--                      <li style="list-style-type: '1. '">CAN/CANFD通信</li>-->
											<!--                      <li style="list-style-type: '2. '">通过网络接口实现高度模块化和可扩展化</li>-->
											<!--                      <li style="list-style-type: '3. '">适用于不同应用领域（MIL、SIL、DIL、VIL、HIL）</li>-->
											<!--                      <li style="list-style-type: '4. '">精确的传感器模型（基于目标信息列表和基于物理的传感器模型）可通过SDK自定义</li>-->
											<!--                      <li style="list-style-type: '5. '">高质量图像实时渲染（PBR技术），支持用户自定义</li>-->
											<!--                      <li style="list-style-type: '6. '">包含各种3D模型库和特定国家/地区的标志/信号数据库</li>-->
										</ol>
									</div>
								</el-col>
								<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
									<div class="technical-advantages">
										<ol ref="rightList"><!--默认圆点类型，可以不写-->
											<!--                      <li style="list-style-type: '7. '">复杂交通工况仿真</li>-->
											<!--                      <li style="list-style-type: '8. '">通过GUI和命令行，轻松地进行实时数据监控和输入</li>-->
											<!--                      <li style="list-style-type: '9. '">可从在个人计算机和完整的HPC环境下运行</li>-->
											<!--                      <li style="list-style-type: '10. '">轻松集成其他Hexagon AB解决方案，如Adams实时精确的传感器建模的车辆动力学模型</li>-->
											<!--                      <li style="list-style-type: '11. '">从Hexagon的Leica Geosystems中获取数据，建立场景模型</li>-->
											<!--                      <li style="list-style-type: '12. '">一千种方案中的边缘案例检测和云端技术支持</li>-->
										</ol>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="electric-solution-components ecusoft-solution-components wow fadeInLeft">
			<div class="container" style="height: 500px">
				<div class="normal-title">核心组件</div>
				<div class="solution-components">
					<div class="components-content">
						<p>网关快速原型硬件设计采用车规级芯片及器件，微控制采用NXP公司的MPC5748G，特性如下：</p>
						<p>高性能：32位，2颗Power Architecture®e200z4 @ 160 MHz/Power，1 颗 Architecture®e200z2 @ 80 MHz</p>
						<p>高稳定性：AEC-Q100，工作温度范围-40 ~ 125℃</p>
						<p>功能丰富：AVB，Ethernet，USB，FlexRay，LIN，CANFD，SAI，DSPI，I2C，SDHC</p>
						<p>安全性：HSM，ISO 26262/IEC 61508</p>
						<p>768KB RAM，6MB Flash</p>
						<br/>
						<p>硬件资源：</p>
						<p>5路100BASE-T1，1路100BASE-TX</p>
						<p>6路CAN/CANFD</p>
						<p>1路LIN Master/Slave</p>
						<p>3路数字输入/2路数字输出</p>
					</div>
				</div>
			</div>
		</section>
		
		<section class="solution-application wow fadeInRight">
			<div class="container">
				<div class="normal-title">应用领域</div>
				<div class="all-application">
					<div class="application-content">
						<div class="application-icon">
							<img src="../../../assets/images/application-picture.svg" alt="">
						</div>
						<div class="application-text">
							<p>支持TC8 L1~L7层测试</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from '@/components/BzInfo'
	import "@/style/vtd.less"

	export default {
		name: "gateway",
		components: {
			mainNav,
			mainFooter,
			bzInfo
		},
		data() {
			return {
				activeIndex: '2-2',
				functionList: [{
						content: "CAN/CANFD通信"
					},
					{
						content: "LIN通信"
					},
					{
						content: "以太网通信"
					},
					{
						content: "SOME/IP通信"
					},
					{
						content: "CAN-LIN-ETH路由"
					},
					{
						content: "UDS on CAN/CANFD诊断"
					},
					{
						content: "UDS on DoIP诊断"
					},
					{
						content: "AUTOSAR网络管理"
					},
					{
						content: "Bootloader on CAN/CANFD"
					},
					{
						content: "Bootloader on Eth"
					},

				],
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
			}
		},
		mounted() {
			new this.$wow.WOW().init();
			document.title = "网关快速原型开发服务 - 解决方案 - 东信创智";
			this.buildUlCount();
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			buildUlCount() {
				const leftList = this.$refs.leftList;
				const rightList = this.$refs.rightList;

				// 计算每列的数量
				const listLength = Math.ceil(this.functionList.length / 2);

				// 动态生成左列列表项
				for (let i = 0; i < listLength; i++) {
					const li = document.createElement("li");
					li.textContent = this.functionList[i].content;
					leftList.appendChild(li);
				}

				// 动态生成右列列表项
				for (let i = listLength; i < this.functionList.length; i++) {
					const li = document.createElement("li");
					li.textContent = this.functionList[i].content;
					rightList.appendChild(li);
				}
			},
		}
	}
</script>

<style scoped lang="less"></style>